<template>
  <div class="form-elements">
    <div class="row">
      <div class="col-md-12">
        <vuestic-widget :loading="loadingAgency">
          <template slot="header">
            <span class="title">
              {{ !isEdit ? 'Add New Agency' : `Edit Agency ${agency ? '#' + agency.id : ''}` }}
            </span>
          </template>
          <ValidationObserver v-slot="{ handleSubmit, invalid, dirty }" ref="agencyForm">
            <form @submit.prevent="handleSubmit(save)">
              <div class="row">
                <div class="col-md-6">
                  <text-input name="first_name" v-model="agency.first_name" label="First Name" :validate="'required'" />
                </div>
                <div class="col-md-6">
                  <text-input name="last_name" v-model="agency.last_name" label="Last Name" :validate="'required'" />
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <text-input name="name" v-model="agency.name" label="Company Name" :validate="'required'" />
                </div>
                <div class="col-md-6">
                  <text-input name="email" v-model="agency.email" label="Registration Email"
                    :validate="'required|email'" />
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <text-input validate="customurl" name="whitelabel.domain" v-model="agency.whitelabel.domain"
                    label="Custom URL" />
                </div>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-6">
                      <text-input name="subdomain_url" v-model="agency.subdomain_url" label="Subdomain"
                        :validate="'required'" />
                    </div>
                    <div class="col-md-6">
                      <text-input v-model="$store.state.app.sitedata.whitelabel_domain" class="subdomain-text"
                        :margin="true" disabled />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6" v-if="!agency.free_fee">
                  <template v-if="isEdit && agency.stripe_plan">
                    <div class="form-group">
                      <div class="input-group select-plan">
                        <label class="control-label">Subscription Plan</label>
                        <select v-model="agency.plan" class="form-control" name="stripe_plan"
                          :disabled="agency.free_fee">
                          <option disabled selected>Select option</option>
                          <option v-for="plan in plans" :value="plan.id" :key="plan.id">{{ getPlanName(plan) }}</option>
                        </select>
                        <i class="bar"></i>
                      </div>
                    </div>
                    <div class="mt-2 d-flex justify-content-between">
                      <p>Status: <span
                          v-bind:class="{ 'text-danger': agency.stripe_status == 'canceled', 'text-primary': agency.stripe_status == 'active' }"><strong>{{ agency.stripe_status }}</strong></span>
                      </p>
                      <div>
                        <template v-if="agency.stripe_active || agency.stripe_status === 'past_due'">
                          <button v-if="agency.plan !== agency.stripe_plan" class="btn btn-sm btn-success ml-2"
                            @click.prevent="handleSubscribe"
                            :disabled="loadingSubscription || agency.stripe_status == 'canceled'"
                            style="min-width: 140px; border-radius: 0">
                            <atom-spinner v-if="loadingSubscription" slot="loading" :animation-duration="1500"
                              :size="14" color="#fff" />
                            <span v-else class="text-white">Change subscription</span>
                          </button>
                          <button class="btn btn-sm btn-danger ml-2" @click.prevent="onCancelSusbscription"
                            :disabled="loadingSubscription || agency.stripe_status == 'canceled'"
                            style="min-width: 140px; border-radius: 0">
                            <atom-spinner v-if="loadingSubscription" slot="loading" :animation-duration="1500"
                              :size="14" color="#fff" />
                            <span v-else class="text-white">Cancel subscription</span>
                          </button>
                        </template>
                        <button v-else class="btn btn-sm btn-success" @click.prevent="handleSubscribe"
                          :disabled="loadingSubscription" style="min-width: 140px; border-radius: 0">
                          <atom-spinner v-if="loadingSubscription" slot="loading" :animation-duration="1500" :size="14"
                            color="#fff" />
                          <span v-else class="text-white">Subscribe</span>
                        </button>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="form-group">
                      <div class="input-group select-plan">
                        <label class="control-label">Default Plan</label>
                        <select v-model="agency.default_plan" class="form-control" name="stripe_plan"
                          :disabled="agency.free_fee">
                          <option disabled selected>Select option</option>
                          <option v-for="plan in plans" :value="plan.id" :key="plan.id">{{ getPlanName(plan) }}</option>
                        </select>
                        <i class="bar"></i>
                      </div>
                    </div>
                  </template>
                </div>
                <div class="col-md-4" v-if="!agency.stripe_active">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <div class="input-group switch-margin">
                          <label class="control-label">Subscription Free</label>
                          <vuestic-switch v-model="agency.free_fee" :disabled="free_fee_disabled" :offcolor=true>
                            <span slot="trueTitle">Yes</span>
                            <span slot="falseTitle">No</span>
                          </vuestic-switch>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="!isTemplate" class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <div class="input-group switch-margin">
                      <label class="control-label">Zapier Enabled</label>
                      <vuestic-switch v-model="agency.zapier_enabled" :offcolor=true>
                        <span slot="trueTitle">Yes</span>
                        <span slot="falseTitle">No</span>
                      </vuestic-switch>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="!isTemplate && agency.zapier_enabled" class="row">
                <div class="col-md-6" v-if="!agency.zapier_free">
                  <div class="form-group">
                    <div class="input-group select-plan">
                      <label class="control-label">Zapier Plan</label>
                      <select v-model="agency.zapier_plan" class="form-control" name="zapier_plan">
                        <option disabled selected>Select option</option>
                        <option v-for="plan in zapPlans" :value="plan.id" :key="plan.id">{{`${plan.name} -
                        $${plan.amount}`}}</option>
                      </select>
                      <i class="bar"></i>
                    </div>
                  </div>
                  <div class="mt-2 d-flex justify-content-between">
                    <p>Status: <span
                        v-bind:class="{ 'text-danger': agency.zapier_status == 'canceled', 'text-primary': agency.zapier_status == 'active' }"><strong>{{ agency.zapier_status }}</strong></span>
                    </p>
                    <button v-if="agency.zapier_active" class="btn btn-sm btn-danger"
                      @click.prevent="onCancelZapSusbscription"
                      :disabled="loadingSubscription || agency.zapier_status == 'canceled'"
                      style="min-width: 140px; border-radius: 0">
                      <atom-spinner v-if="loadingSubscription" slot="loading" :animation-duration="1500" :size="14"
                        color="#fff" />
                      <span v-else class="text-white">Cancel subscription</span>
                    </button>
                    <button v-else class="btn btn-sm btn-success" @click.prevent="handleSubscribe('zaiper')"
                      :disabled="loadingSubscription" style="min-width: 140px; border-radius: 0">
                      <atom-spinner v-if="loadingSubscription" slot="loading" :animation-duration="1500" :size="14"
                        color="#fff" />
                      <span v-else class="text-white">Subscribe</span>
                    </button>
                  </div>
                </div>
                <div class="col-md-4" v-if="!agency.zapier_active">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <div class="input-group switch-margin">
                          <label class="control-label">Zapier Free</label>
                          <vuestic-switch v-model="agency.zapier_free" :disabled="agency.zapier_active" :offcolor=true>
                            <span slot="trueTitle">Yes</span>
                            <span slot="falseTitle">No</span>
                          </vuestic-switch>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="!isTemplate" class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <div class="input-group switch-margin">
                      <label class="control-label">Department Enabled</label>
                      <vuestic-switch v-model="agency.department_enabled" :offcolor=true>
                        <span slot="trueTitle">Yes</span>
                        <span slot="falseTitle">No</span>
                      </vuestic-switch>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="!isTemplate" class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <div class="input-group switch-margin">
                      <label class="control-label">Automation Library</label>
                      <vuestic-switch v-model="agency.automation_library" :offcolor=true>
                        <span slot="trueTitle">Yes</span>
                        <span slot="falseTitle">No</span>
                      </vuestic-switch>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="!isTemplate" class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <div class="input-group switch-margin">
                      <label class="control-label">RemoveBranding Enabled</label>
                      <vuestic-switch v-model="agency.remove_branding" :offcolor=true>
                        <span slot="trueTitle">Yes</span>
                        <span slot="falseTitle">No</span>
                      </vuestic-switch>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-12 d-flex justify-content-between">
                  <button v-if="isEdit" type="button" @click="impersonate()"
                    class="ml-2 btn btn-custom btn-default btn-primary" v-b-tooltip.hover title="Log In To Sub Account">
                    Login <i class="fa fa-sign-in"></i>
                  </button>
                  <router-link v-else class="btn btn-danger btn-primary" :to="{ name: 'agencyaccounts' }">
                    <span class="text-white">Back</span>
                  </router-link>
                  <button class="btn btn-primary" :disabled="loading || invalid && dirty">{{
                    isEdit? 'Update':
                      'Submit'
                  }}</button>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </vuestic-widget>
        <vuestic-widget v-if="agency.id">
          <ValidationObserver v-slot="{ handleSubmit, invalid }" ref="passwordForm">
            <form @submit.prevent="handleSubmit(handleUpdatePassword)">
              <div class="row">
                <div class="col-md-6">
                  <text-input name="new_password" v-model="newPassword.password" label="New Password"
                    validate="required" ref="new_password" type="password" />
                </div>
                <div class="col-md-6">
                  <text-input name="confirm_password" v-model="newPassword.password_confirmation"
                    label="Confirm password" :validate="`required|password:${newPassword.password}`" type="password" />
                </div>
              </div>
              <div class="mt-2">
                <button class="btn btn-primary" :disabled="loadingUpdatePassword || invalid">
                  <atom-spinner v-if="loadingUpdatePassword" slot="loading" :animation-duration="1500" :size="14"
                    color="#FFF" />
                  <span v-else>Update</span>
                </button>
              </div>
            </form>
          </ValidationObserver>
        </vuestic-widget>
        <vuestic-widget v-if="!isTemplate && agency.id">
          <ValidationObserver v-slot="{ handleSubmit }" ref="twilioForm">
            <form @submit.prevent="handleSubmit(updateTwilioConfig)">
              <div class="row">
                <div class="col-md-6">
                  <text-input name="sid" v-model="agency.twilioConfig.aSID" label="Twilio Account SID" />
                </div>
                <div class="col-md-6">
                  <text-input name="token" v-model="agency.twilioConfig.token" label="Twilio Auth Token" />
                </div>
              </div>
              <div class="mt-2">
                <button class="btn btn-primary" :disabled="loadingTwilioConfig">
                  <atom-spinner v-if="loadingTwilioConfig" slot="loading" :animation-duration="1500" :size="14"
                    color="#FFF" />
                  <span v-else>Update</span>
                </button>
              </div>
            </form>
          </ValidationObserver>
        </vuestic-widget>
        <vuestic-widget v-if="!isTemplate && agency.id">
          <ValidationObserver v-slot="{ handleSubmit }" ref="stripeForm">
            <form @submit.prevent="handleSubmit(updateStripeConfig)">
              <div class="row">
                <div class="col-md-6">
                  <text-input name="sid" v-model="agency.stripeConfig.publicKey" label="Stripe Public Key" />
                </div>
                <div class="col-md-6">
                  <text-input name="token" v-model="agency.stripeConfig.secretKey" label="Stripe Secret Key" />
                </div>
              </div>
              <div class="mt-2">
                <button class="btn btn-primary" :disabled="loadingStripeConfig">
                  <atom-spinner v-if="loadingStripeConfig" slot="loading" :animation-duration="1500" :size="14"
                    color="#FFF" />
                  <span v-else>Update</span>
                </button>
              </div>
            </form>
          </ValidationObserver>
        </vuestic-widget>
      </div>
    </div>
    <vuestic-modal :isOpen="isOpenModalCancel" @ok="handleCancelSubscription" @cancel="isOpenModalCancel = false"
      okText="Cancel" cancelText="Ignore" :closeOnOk="false" :processing="loadingSubscription"
      okClass="btn btn-danger btn-primary">
      <span slot="title" class="text-danger font-weight-bold">Cancel Subscription</span>

      <div>Confirm you want to cancel this subscription?</div>
    </vuestic-modal>
  </div>
</template>

<script>
export default {
  name: 'AgencyAccount',
  components: {},

  watch: {
    $route(to, from) {
      Object.assign(this.$data, this.$options.data.apply(this));
    }
  },
  data() {
    return {
      isEdit: false,
      loadingAgency: false,
      mailMsg: '',
      subdomainText: '',
      agency: {
        id: undefined,
        user_id: undefined,
        name: '',
        subdomain_url: '',
        free_fee: false,
        properties: {
          installs: 5,
          monthlyCostIsFree: true,
          sendRegistration: false,
        },
        email: '',
        firstName: '',
        lastName: '',
        whitelabel: {
          domain: '',
          type: 'subdomain',
        },
        zapier_free: false,
        plan: undefined,
      },
      newPassword: {
        password: null,
        password_confirmation: null,
      },
      loadingSubscription: false,
      loadingTwilioConfig: false,
      loadingStripeConfig: false,
      loadingUpdatePassword: false,
      zapPlans: [],
      isOpenModalCancel: false,
      subscriptionType: null,
    }
  },

  created() {
    if (this.$route.params.id) {
      this.isEdit = true
      this.loadingAgency = true

      this.loadData()
    } else {
      this.isEdit = false
    }
  },

  mounted() {
    if (!this.$store.getters['subscription/isLoadedPlan']) {
      this.$store.dispatch('subscription/getPlans')
    }

    this.$store.dispatch('subscription/getPlansByType', 'zapier')
      .then((res) => {
        if (res && res.length)
          this.zapPlans = res
      })
      .catch((err) => {
      })
  },

  methods: {
    impersonate() {
      this.$store.dispatch('auth/impersonate', this.agency.user.id).then(success => {
      }).catch(error => {
        console.log('Error', error);
      });
    },

    loadData(id) {
      this.$store.dispatch('agency/getAgency', this.$route.params.id)
        .then(res => {
          this.agency = {
            ...this.agency,
            ...res.data
          }
          this.agency.plan = res.data.stripe_plan
          this.agency.user_id = res.data.user.id
          this.agency.email = res.data.user.email
          this.agency.first_name = res.data.user.first_name
          this.agency.last_name = res.data.user.last_name
          this.$route.meta.displayName = `Edit ${this.agency.name}`
          this.loadingAgency = false
        })
        .catch(errors => {
          this.loadingAgency = false
        })
    },

    getPlanName(plan) {
      return `${plan.name} - $${plan.amount} - Installs: ${plan.features.Installs}`
    },

    save() {
      // Update
      if (this.agency.id) {
        this.$store.dispatch('agency/updateAgency', this.agency)
          .then((res) => {
          })
          .catch(errors => {
            this.$refs.agencyForm.setErrors(errors.response.data.errors)
          })
      } else {
        this.$store.dispatch('agency/createAgency', this.agency)
          .then((res) => {
            this.$router.push({ name: 'agencyaccounts' })
          })
          .catch(errors => {
            this.$refs.agencyForm.setErrors(errors.response.data.errors)
          })
      }
    },
    handleSubscribe(type = undefined) {
      if (type != 'zapier' && !this.agency.plan) {
        Vue.$toast.open({
          message:
            "Please select subscription plan.",
          type: "error",
        });
        return
      }
      if (type == 'zapier' && !this.agency.zapier_plan) {
        Vue.$toast.open({
          message:
            "Please select zapier plan.",
          type: "error",
        });
        return
      }
      const params = {
        id: this.agency.user_id,
        plan: this.agency.plan
      }
      if (type == 'zapier') {
        params.plan = this.agency.zapier_plan
        params.type = type
      }
      this.loadingSubscription = true
      this.$store.dispatch('subscription/userSubscriptionPlan', params).then((res) => {
        this.$store.dispatch('auth/getProfile')
        this.loadData()
        this.loadingSubscription = false
      })
        .catch((err) => {
          this.loadingSubscription = false
        })
    },

    onCancelSusbscription() {
      this.subscriptionType = 'default'
      this.isOpenModalCancel = true
    },

    onCancelZapSusbscription() {
      this.subscriptionType = 'zapier'
      this.isOpenModalCancel = true
    },

    handleCancelSubscription() {
      this.cancelSusbscription()
    },

    cancelSusbscription() {
      this.loadingSubscription = true
      const params = {
        userId: this.agency.user.id,
      }
      if (this.subscriptionType) {
        params.type = this.subscriptionType
      }

      this.$store.dispatch('subscription/cancelSubscription', params)
        .then((res) => {
          if (this.subscriptionType === 'zapier') {
            this.agency.zapier_plan = null
            this.agency.zapier_status = 'canceled'
            this.agency.zapier_active = false
            this.agency.zap_enabled = false
          } else {
            this.agency.stripe_plan = null
            this.agency.plan = null
            this.agency.stripe_status = 'canceled'
            this.agency.stripe_active = false
          }
          this.loadingSubscription = false
          this.isOpenModalCancel = false
        })
        .catch(errors => {
          this.loadingSubscription = false
          this.isOpenModalCancel = false
        })
    },

    updateTwilioConfig() {
      this.loadingTwilioConfig = true
      this.$refs.twilioForm.validate().then(success => {
        if (!success) {
          this.loadingTwilioConfig = false;
        }

        this.$store.dispatch('agency/updateTwilioConfig', this.agency.twilioConfig)
          .then((res) => {
            this.loadingTwilioConfig = false
          })
          .catch(errors => {
            this.loadingTwilioConfig = false
          })
      });
    },

    updateStripeConfig() {
      this.loadingStripeConfig = true
      this.$refs.stripeForm.validate().then(success => {
        if (!success) {
          this.loadingStripeConfig = false;
        }

        const data = {
          id: this.agency.id,
          data: this.agency.stripeConfig
        }

        this.$store.dispatch('agency/updateStripeSetting', data)
          .then((res) => {
            this.loadingStripeConfig = false
          })
          .catch(errors => {
            this.loadingStripeConfig = false
          })
      });
    },

    handleUpdatePassword() {
      this.loadingUpdatePassword = true;

      this.$store.dispatch('agency/changePassword', { id: this.agency.id, params: this.newPassword })
        .then((res) => {
          this.loadingUpdatePassword = false
          this.newPassword = this.$options.data().newPassword
          this.$refs.passwordForm.reset()
        })
        .catch(errors => {
          this.loadingUpdatePassword = false
        })
    },
  },

  computed: {
    loading() {
      return this.$store.state.agency.loading
    },

    plans() {
      return this.$store.getters['subscription/plans']
    },

    free_fee_disabled() {
      return this.agency.stripe_active
    },

    isTemplate() {
      return this.agency.is_template
    }
  },
}
</script>
<style lang="scss" scoped>
a {
  color: #3578c6 !important;

  &[disabled] {
    opacity: 0.6;
  }
}

.form-group {
  .control-label {
    font-size: 0.6rem;
    color: #333;
    font-weight: 600;
    text-transform: uppercase;
    top: -.6rem;
    left: 0;
  }

  select {
    width: 100%;
    background: none !important;
    border: .0625rem solid #e7e7e7;
    border-bottom: none;
    border-radius: .3rem;
  }

  .select-plan label {
    position: initial;
    margin-bottom: 0px;
  }

  .switch-margin label {
    position: initial;
    margin-bottom: 0px
  }
}

.atom-spinner {
  display: inline-block;
}

.vuestic-switch {
  width: 100%;
}
</style>
